import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/UIElements/Logo';
import HamburgMenu from 'common/components/HamburgMenu';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import { Container } from './navbar.style';

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import CommuteIcon from '@material-ui/icons/Commute';

import LogoImage from 'common/assets/image/logo.webp';

import { DrawerContext } from 'common/contexts/DrawerContext';

const Navbar = ({ page, navbarStyle, logoStyle, openLogin, theme = null }) => {
  const { state, dispatch } = useContext(DrawerContext);
  // Authentication modal handler
  const handleLoginModal = () => {
    openLogin();
  };

  const handlePassengerClick = () => {

  }

  const handleDriverClick = () => {
    navigate('/driver');
  }

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      appJson {
        menuItems {
          label
          path
          offset
        }
      }
    }
  `);

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container theme={theme}>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Agency"
          logoStyle={logoStyle}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            page === 'landing' ? (
              <>
                <Button
                  variant="textButton"
                  icon={<EmojiPeopleIcon fontSize="large" />}
                  aria-label="passenger"
                  title="Sign Up to Ride"
                  iconPosition="left"
                />
                <Button
                  variant="textButton"
                  onClick={handleDriverClick}
                  icon={<CommuteIcon fontSize="large" />}
                  aria-label="driver"
                  title="Apply To Drive"
                  iconPosition="left"
                />
              </>
            ) : (
              <>
                <Button
                  variant="textButton"
                  className="btn--driver-signup"
                  onClick={handleLoginModal}
                  icon={<i className="flaticon-user" />}
                  aria-label="login"
                />
                <Drawer
                  width="420px"
                  placement="right"
                  drawerHandler={<HamburgMenu />}
                  open={state.isOpen}
                  toggleHandler={toggleHandler}
                >
                  <ScrollSpyMenu
                    menuItems={Data.appJson.menuItems}
                    drawerClose={true}
                    offset={-100}
                  />
                </Drawer>
              </>
            )
          }
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: ['50px', '60px'],
  },
  buttonStyle: {
    minHeight: '70px',
    color: '#fff',
  },
};

export default Navbar;
